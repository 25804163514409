@import "bootstrap/bootstrap";
@import "admin-lte/variables.less";


@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 300;
}

.error-page {
	background-color: @body-bg;
}